.footer {
  background-color: #01021d;
}

.footer-description {
  font-size: 2rem;
  color: #e6ffe6;
}

.footer-description-secondary {
  font-size: 1.4rem;
  color: #e6ffe6;
}

.footer-link:link,
.footer-link:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  color: #00fa8e;
}

.footer-div {
  text-align: center;
  padding-top: 50px;
  line-height: 1.4;
}

.footer-div::after {
  content: "";
  display: block;
  width: 90px;
  height: 2px;
  margin: 0 auto;
  background-color: #e6ffe6;
  margin-top: 15px;
  /* margin-bottom: 45px; */
}

p b {
  font-size: inherit;
}

.year {
  padding-bottom: 10px;
  margin-left: 10px;
  font-size: 1.6rem;
  color: #fff;
}

@media (max-width: 730px) {
  .footer-description {
    font-size: 1.4rem;
  }

  .footer-div::after {
    width: 60px;
  }

  .year {
    font-size: 1.2rem;
  }

  .footer-div::after {
    margin-top: 2px;
    margin-bottom: 1rem;
  }
}
