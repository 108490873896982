* {
  font-size: 62.5%;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

html {
  background-color: #01021d;
}

html,
body {
  overscroll-behavior-y: none;
  overflow-x: hidden;
}

.section {
  padding-top: 72px;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #01021d;
}

:target:before {
  content: "";
  display: block;
  height: 70px;
  margin: -70px 0 0;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #01021d;
}

::-webkit-scrollbar-thumb {
  background: #00fa8e;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #02a55f;
}

::selection {
  color: #00fa8e;
  background: #ffffff2f;
}

@media (max-width: 730px) {
  .section {
    padding-top: 0;
  }
}
