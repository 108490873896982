.nav {
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  padding: 1rem 3rem;
  z-index: 100;
  transition: all 0.3s ease;
}

.move-nav {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.938);
  transform: translateY(-73px);
  backdrop-filter: blur(20px);
}

.secondary-nav {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.397);
  backdrop-filter: blur(20px);
}

.logo {
  max-width: 5rem;
}

.nav--list {
  display: flex;
  gap: 5rem;
  list-style: none;
  margin-left: auto;
  transition: all 0.3s ease;
}

.nav--list-link:link,
.nav--list-link:visited {
  text-decoration: none;
  font-size: 1.8rem;
  color: #ebeeeb;
  transition: all 0.3s ease;
}

.nav--list-link:hover {
  color: #00fa8e;
}

.active {
  color: #00fa8e !important;
}

.nav--list-btn {
  border: 2px solid #00fa8e;
  padding: 1rem 2rem;
  border-radius: 10px;
  color: #00fa8e !important;
  transition: all 0.3s ease;
}

.nav--list-btn:hover {
  background-color: #a3dda36e;
}

.open-nav-btn {
  display: none;
  margin-left: auto;
  font-size: 1.2rem;
  padding: 1rem 2rem;
  transition: all 0.3s ease;
}

.close-nav-btn {
  display: none;
  margin-left: auto;
  font-size: 1.2rem;
  padding: 1rem 2rem;
}

ion-icon {
  color: #fff;
  font-size: 40px;
  transition: all 0.3s ease;
}

.item2 {
  display: none;
}

@media (max-width: 750px) {
  .nav {
    padding: 1rem 1.5rem 1rem 1rem;
  }
}

@media (max-width: 600px) {
  .nav--list {
    position: absolute;
    flex-direction: column;
    top: 0;
    right: 0;
    height: 100vh;
    background: rgba(1, 2, 29, 0.884);
    padding: 3rem;
    padding-top: 5rem;
    transform: translateX(105%);
    transition: all 0.3s ease-in;
    font-weight: 500;
  }

  .item1 {
    display: none;
  }

  .item2 {
    display: inline-block;
  }

  .nav--list-item:last-child {
    margin-top: auto;
    margin-bottom: 10rem;
  }

  .show-nav {
    transform: translateX(0);
  }

  .hide-nav {
    transform: translateX(105%);
  }

  .open-nav-btn {
    display: block;
  }

  .close-nav-btn {
    display: block;
  }

  .nav--list-link:link,
  .nav--list-link:visited {
    font-size: 2.2rem;
  }

  .move-nav {
    transform: translateY(-84px);
  }
}
