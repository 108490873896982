.main {
  min-width: 100vh;
  min-height: 100vh;
  background-color: #252e2f;
  background-color: #01021d;
  pointer-events: none;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.26);
}

.vertical {
  writing-mode: vertical-lr;
  transform: scaleY(-1);
}

.heading-primary {
  position: relative;
  font-size: 9rem;
  color: #ebeeeb;
  z-index: 3 !important;
}

.hi {
  position: relative;
  font-size: 12rem;
  color: #00fa8e;
  z-index: 3 !important;
  animation-name: lefttoright;
  animation-duration: 400ms;
  animation-delay: 250ms;
  animation-timing-function: ease-in-out;
}

@keyframes lefttoright {
  from {
    left: -250px;
    opacity: 0;
  }
  to {
    left: 0px;
    opacity: 1;
  }
}

.heading-secondary {
  position: relative;
  font-size: 6.4rem;
  color: #e6ffe6;
  z-index: 3 !important;
}

.subheading {
  position: relative;
  font-size: 2.4rem;
  text-transform: uppercase;
  color: #00fa8e;
  z-index: 3 !important;
}

.description {
  position: relative;
  font-size: 2rem;
  max-width: 70rem;
  line-height: 0.9;
  color: #00fa8e;
  z-index: 3 !important;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-container {
  position: relative;
}

.img-container {
  position: absolute;
  top: 0;
}

.img-layer {
  position: fixed;
  width: 95vw;
  height: 95vh;
  top: 20px;
  left: 300px;
  background-position: right;
  background-size: cover;
}

.img-layer:nth-child(1) {
  background-image: url("../../Assets/MainPage/1.png");
}
.img-layer:nth-child(2) {
  background-image: url("../../Assets/MainPage/2.png");
}
.img-layer:nth-child(3) {
  background-image: url("../../Assets/MainPage/3.png");
}
.img-layer:nth-child(4) {
  background-image: url("../../Assets/MainPage/4.png");
}
.img-layer:nth-child(5) {
  background-image: url("../../Assets/MainPage/5.png");
}
.img-layer:nth-child(6) {
  background-image: url("../../Assets/MainPage/6.png");
}
.img-layer:nth-child(7) {
  background-image: url("../../Assets/MainPage/7.png");
  /* z-index: -5; */
}
.img-layer:nth-child(8) {
  background-image: url("../../Assets/MainPage/1.png");
  transform: scaleY(-1);
  left: -650px;
}

@media (max-width: 1700px) {
  .img-layer:nth-child(8) {
    left: -450px;
  }
}

@media (max-width: 1550px) {
  .img-layer {
    left: 350px;
  }
}

@media (max-width: 1035px) {
  .heading-primary {
    font-size: 6.4rem;
  }

  .heading-secondary {
    font-size: 5rem;
  }

  .subheading {
    font-size: 1.8rem;
  }

  .description {
    font-size: 1.6rem;
  }
}

@media (max-width: 940px) {
  .img-layer:nth-child(8) {
    /* display: none; */
  }
  .img-layer {
    left: 150px;
    width: 100vw;
    height: 100vh;
  }
}

@media (max-width: 787px) {
  .img-layer:nth-child(8) {
    display: none;
  }

  .heading-primary {
    font-size: 5rem;
  }

  .heading-secondary {
    font-size: 3.5rem;
  }

  .subheading {
    font-size: 1.2rem;
  }

  .description-main {
    font-size: 1.4rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.596);
  }

  .img-layer {
    left: 0px;
  }

  .container {
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.747);
    justify-content: flex-start;
    padding-left: 35px;
  }
}

@media (max-width: 730px) {
  .container {
    padding-right: 150px;
    padding-left: 15px;
  }
}

@media (max-width: 600px) {
  .headings-container {
    max-width: 450px;
  }

  .img-layer {
    position: absolute;
  }
}

@media (max-width: 510px) {
  .container {
    padding-left: 0px;
  }
}

@media (max-width: 496px) {
  .heading-primary {
    font-size: 3.5rem;
  }

  .heading-secondary {
    font-size: 2.4rem;
  }

  .subheading {
    font-size: 1.2rem;
  }

  .description-main {
    font-size: 1.3rem;
    text-shadow: 0px 0px 1px rgb(0, 0, 0);
  }
}

@media (max-width: 400px) {
  .headings-container {
    max-width: 300px;
  }
}
