.work-img {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  transition: all 0.3s ease;
}

.work-container {
  padding-bottom: 80px;
}

.project-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
}

.work-container .subheading {
  color: #3effac8f;
  font-size: 1.2rem;
}

.desc-container {
  padding: 15px 35px;
  position: relative;
  /* position: absolute; */
  max-width: 58.5rem;
  border-radius: 25px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.555);
  transition: all 0.3s ease;
  z-index: 2;
}

.gradient-toleft {
  background-image: linear-gradient(to left, #000350, #01021d);
  transition: all 0.3s ease;
}

.gradient-toleft:hover {
  background-image: linear-gradient(to left, #000350, #000350);
}

.gradient-toright {
  background-image: linear-gradient(to right, #000350, #01021d);
  transition: all 0.3s ease;
}

.gradient-toright:hover {
  background-image: linear-gradient(to right, #000350, #000350);
}

.desc-container:hover {
  border-radius: 15px;
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.685);
}

.project-description {
  font-size: 1.5rem;
  color: #00ff91;
}

.project-link {
  position: relative;
  background-color: #01021d;
}

.project-link:hover > .work-img:hover {
  border-radius: 15px;
  transform: translateY(-5px);
  box-shadow: 0 20px 30px rgba(75, 75, 75, 0.295);
}

.project-link:hover > .project-src-link {
  transform: translateY(-5px);
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.425);
}

#src-link:hover {
  color: #00ff91;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.685);
}

.container-img {
  grid-column: 1/8;
}

.info-container {
  grid-column: 7/-1;
}

.right {
  grid-area: 1/7/-1/-1;
}

.right-text {
  grid-area: 1/6/-1/-1;
}

.left {
  grid-area: 1/1/-1/8;
}

.left-image {
  grid-area: 1/1/-1/7;
}

.align--right {
  text-align: right;
}

.align--right-absolute {
  right: 0;
}

.tech-stack-list {
  position: absolute;
  display: flex;
  gap: 15px;
  list-style: none;
  color: #fff;
  font-size: 2.4rem;
  bottom: 25px;
  z-index: 5;
}

.tech-stack-list-item {
  font-weight: 500;
  letter-spacing: 1px;
}

.project-src-link:link,
.project-src-link:visited {
  position: absolute;
  display: inline-block;
  z-index: 2;
  right: 0;
  text-decoration: none;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  background-color: #01021d;
  color: #fff;
  font-weight: 500;
  transition: all 0.3s ease;
}

@media (max-width: 1035px) {
  .desc-container {
    padding: 10px 20px;
  }
}

@media (max-width: 910px) {
  .tech-stack-list {
    bottom: 0;
  }
}

@media (max-width: 800px) {
  .project-description {
    font-size: 1.2rem;
  }

  .work-container .subheading {
    font-size: 1rem;
  }
}

@media (max-width: 650px) {
  .project-description {
    font-size: 1rem;
  }

  .desc-container {
    padding: 7px 15px;
  }

  .tech-stack-list {
    font-size: 1.8rem;
  }

  .project-src-link:link,
  .project-src-link:visited {
    font-size: 1.2rem;
    padding: 0.6rem 1.2rem;
  }
}

@media (max-width: 630px) {
  .project-container {
    grid-template-columns: 1fr;
    align-items: flex-end;
    padding-bottom: 20px;
  }

  .work-container .subheading {
    color: #00ff91;
    font-size: 0.9rem;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.452);
    padding-right: 5px;
    padding-left: 5px;
  }

  .work-container .heading-secondary {
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    padding-right: 5px;
    padding-left: 5px;
  }

  .tech-stack-list {
    padding-right: 5px;
    padding-left: 5px;
  }
}
