.container-about {
  text-align: center;
}

.section-headings {
  text-align: center;
  font-size: 7.2rem;
  color: #e6ffe6;
  margin-bottom: 100px;
}

.section-headings::after {
  content: "";
  display: block;
  width: 90px;
  height: 2px;
  margin: 0 auto;
  background-color: #e6ffe6;
  margin-bottom: 45px;
}

.container-about-me {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
}

.about-me {
  max-width: 500px;
  text-align: center;
  color: #ebeeeb;
  font-size: 1.8rem;
}

.tech-stack {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 2rem 1rem 3rem;
  margin: 100px auto 50px auto;
  color: #ebeeeb;
  font-size: 3rem;
  line-height: 1.7;
  /* box-shadow: 26px 26px 0px #010110, -26px -26px 0px #01032a; */
  border-radius: 10px;
  max-width: 500px;
  list-style: none;
}

li::marker {
  /* content: "•"; */
  color: #00fa8e;
}

.tech-stack span {
  display: inline-block;
  color: #00fa8e;
  font-size: 3rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

.tech-stack-item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

@media (max-width: 940px) {
  .tech-stack {
    max-width: 350px;
  }
}

@media (max-width: 730px) {
  .section-headings {
    font-size: 4rem;
    margin-bottom: 40px;
  }

  .about-me {
    font-size: 1.4rem;
    margin: 0 75px;
    line-height: 1.6;
  }

  .tech-stack {
    font-size: 2.4rem;
  }

  .icon {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 630px) {
  .tech-stack {
    max-width: 300px;
  }
}
