#section-contact {
  min-height: auto !important;
  max-height: 100vh;
}

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.contact-description {
  font-size: 2rem;
  max-width: 500px;
  text-align: center;
  color: #fff;
}

.contact-link:link,
.contact-link:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 1.8rem;
  padding: 0.8rem 1.6rem;
  width: 100%;
  height: 100%;
  color: #00fa8e;
}

@media (max-width: 730px) {
  .contact-description {
    font-size: 1.4rem;
    line-height: 1.6;
    margin: 0 50px;
  }
}
