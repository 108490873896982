.project-section {
  margin: 0px auto;
  max-width: 1000px;
}

#section-work {
  padding-right: 10rem;
  padding-left: 10rem;
}

@media (max-width: 885px) {
  #section-work {
    padding-right: 7rem;
    padding-left: 7rem;
  }
}

@media (max-width: 630px) {
  #section-work {
    margin-top: 50px;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

@media (max-width: 550px) {
  #section-work {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media (max-width: 400px) {
  #section-work {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

@media (max-width: 3600px) {
  #section-work {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
